@import '../../themes/basic-vars.scss';

.maimai-crm-basic-table .ant-spin {
    display: flex !important;
    align-items: center;
    justify-content: center;
    // transform: translateY(25%);
}
.maimai-crm-button.ant-btn {
    font-weight: 500;
    &:hover {
        opacity: 0.6;
    }
    &:focus {
        opacity: 0.6;
    }
    &:active {
        opacity: 0.4;
    }
}

.maimai-crm-button.ant-btn-lg {
    font-size: 12px;
    height: 36px;
}

.maimai-crm-dropdownbtn.ant-btn-group.ant-btn-group-lg {
    & > .ant-btn,
    .ant-btn-group-lg > span > .ant-btn {
        font-size: 12px;
        height: 36px;
        line-height: 34px;
    }
}
.maimai-crm-button.ant-btn-default {
    font-size: 12px;
}

.maimai-crm-button.ant-btn-default {
    border-color: transparent;
    background-color: #f4f5fa;
    &:hover {
        border-color: transparent;
        background-color: #f4f5fa;
        color: initial;
    }
    &:focus {
        border-color: transparent;
        background-color: #f4f5fa;
        color: initial;
    }
    &:active {
        border-color: transparent;
        background-color: #f4f5fa;
        color: initial;
    }
}

.maimai-crm-table {
    .ant-table-placeholder {
        background-color: transparent;
    }
    .ant-table {
        font-size: 12px;
    }
    .ant-table-row.table-hidden {
        visibility: hidden;
    }
    .ant-table-header table {
        overflow: hidden;
    }
    .ant-table-thead > tr,
    .ant-table-tbody > tr {
        // height: 43px !important;
    }
    .ant-table-fixed-left table,
    .ant-table-fixed-right table {
        width: 0;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        // padding: 11px 11px;
        padding: 9px 9px;
        line-height: 1;
    }
    .ant-table-tbody > tr {
        &:last-of-type > td {
            border-bottom: none;
        }
    }
    .ant-table-thead
        > tr
        > th
        .ant-table-header-column
        .ant-table-column-sorters
        > *:not(.ant-table-column-sorter) {
        position: initial;
    }
    .ant-table-fixed-header .ant-table-body-inner {
        // overflow: auto !important;
        // padding-bottom: 16px;
    }
    .ant-table-fixed-header
        > .ant-table-content
        > .ant-table-scroll
        > .ant-table-body {
        // overflow: auto !important;
    }

    .ant-table-body > [vt] {
        min-width: 100%;
    }

    .ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) {
        .super-th.super-th-highlight .th-filter-icon {
            position: relative;
            top: -1000px;
        }
    }
    .ant-table-thead > tr > th {
        color: $text-color-secondary;
        border: none;
        background-color: #f6f6fb;
        position: relative;
        line-height: 1;
        .super-th {
            display: flex;
            height: 16px;
            align-items: center;
            // 也不知道为啥，flex就会小小的错位
            // position: relative;
            // top: 2px;
            .th-title {
                flex-shrink: 1;
            }
        }
        .after-op-slot {
            position: absolute;
            display: inline-block;
            height: 100%;
            line-height: 38px;
            right: 0;
            width: 38px;
            top: 0;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
            border-left: 1px solid #ddd;
            &:hover {
                color: #0052ff;
            }
        }
        .super-th.super-th-highlight {
            .th-filter-icon {
                display: initial;
                color: $link-color;
            }
        }
        &:hover {
            .th-filter-icon {
                display: initial;
            }
        }
        .th-filter-icon {
            flex-shrink: 0;
            display: none;
            padding-left: 7px;
            font-size: 16px;
            &:hover {
                color: $link-color;
            }
        }
        .th-spliter {
            position: absolute;
            right: 0;
            top: 0;
            width: 6px;
            height: 34px;
            cursor: col-resize;
            .th-spliter-inner {
                display: inline-block;
                height: 34px;
                border-right: 1px solid #ccc;
                transform: scaleX(0.5);
                position: absolute;
                right: 1px;
                top: 0;
                pointer-events: none;
            }
        }
    }
    .ant-table-header {
        background-color: transparent;
    }

    .ant-table-scroll {
        .ant-table-thead > tr > th {
            &:first-of-type {
                border-top-left-radius: $border-radius-base;
                border-bottom-left-radius: $border-radius-base;
            }
            &:last-of-type {
                border-top-right-radius: $border-radius-base;
                border-bottom-right-radius: $border-radius-base;
                .th-spliter {
                    display: none;
                }
            }
        }
    }
    .ant-table-fixed-left {
        .ant-table-thead > tr > th {
            &:first-of-type {
                border-top-left-radius: $border-radius-base;
                border-bottom-left-radius: $border-radius-base;
            }
        }
    }
    .ant-table-fixed-right {
        .ant-table-thead > tr > th {
            &:last-of-type {
                border-top-right-radius: $border-radius-base;
                border-bottom-right-radius: $border-radius-base;
                .th-spliter {
                    display: none;
                }
            }
        }
    }
}
.maimai-crm-table.maimai-crm-table-fixed-td {
    .ant-table-layout-fixed .ant-table-scroll .ant-table-fixed {
        colgroup {
            col {
                &:last-of-type {
                    width: unset !important;
                    min-width: unset !important;
                }
            }
        }
    }
}
.ant-tabs-tab {
    padding: 16px !important;
    font-weight: 500;
}
.maimai-crm-collapse {
    background-color: #fff !important;
    .ant-collapse-item {
        border: none !important;
    }
    .ant-collapse-header {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        padding: 10px 15px !important;
        svg {
            transition: transform 0.24s;
        }
    }
}
.crm-inputarea-content {
    position: relative;
    .count {
        position: absolute;
        bottom: 8px;
        right: 8px;
        font-weight: 400;
        color: #d1d3de;
        cursor: default;
    }
}
