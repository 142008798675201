@import '../../themes/basic-vars.scss';

.maimai-crm-drawer.ant-drawer-open {
    .ant-drawer-close {
        opacity: 1;
    }
}

.maimai-crm-drawer {
    .ant-drawer-close {
        transition: all 200ms;
        opacity: 1;

        &:hover {
            opacity: 0.6;
            color: white !important;
        }

        color: white;
        display: inline-block;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;

        border: 1px solid #eee;
        background-color: #a4a7ac;

        border-radius: $border-radius-base;
        width: 40px;
        height: 40px;
        // right: 15px;
        right: 0;
        // top: 18px;
        top: 0;
        z-index: 1;
    }

    .ant-drawer-content-wrapper {
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
        border-radius: 3px 0px 0px 3px;

        .ant-drawer-content {
            overflow: visible;
            background-color: transparent;

            .ant-drawer-wrapper-body {
                overflow: visible;
            }
        }

    }



    .ant-drawer-body {
        height: calc(100% - 76px);
        position: relative;
        transition: width 200ms;
    }

    .ant-drawer-header {
        position: relative;
        padding: 26px 80px 30px;
        border-bottom: none;
        padding-left: 30px;

        .ant-drawer-title {
            margin: 0;
            color: #15161f;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;

            .arrow {
                position: absolute;
                left: 4px;
                z-index: 15;
                top: 32px;
                transform: scale(0.9);
            }
        }
    }
}